import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import {
  container,
  siteTitle,
  siteSubtitle
} from './layout.module.css'


const Layout = ({ pageTitle, children }) => {
	const data = useStaticQuery(graphql`
		query {
		  site {
			siteMetadata {
			  title
			}
		  }
		}
	  `)
  return (
	<div className={container}>
	  <title>{pageTitle} | {data.site.siteMetadata.title}</title>
	  <header>
		  <h1 className={siteTitle}>This<br/>Seeming<br/>Chaos</h1>
		  <h2 className={siteSubtitle}>Excerpts from my journal</h2>
	   </header>
	  <main>
		{children}
	  </main>
	</div>
  )
}
export default Layout
