// extracted by mini-css-extract-plugin
export var container = "layout-module--container--eLBMS";
export var siteTitle = "layout-module--site-title--5N6mf";
export var siteSubtitle = "layout-module--site-subtitle--mQAuu";
export var date = "layout-module--date--5uAog";
export var card = "layout-module--card--r1e7A";
export var postScript = "layout-module--postScript--g3tSd";
export var received = "layout-module--received--A-yWq";
export var divider = "layout-module--divider--K4p4x";
export var alive = "layout-module--alive--zDmIt";
export var hidden = "layout-module--hidden--tNnld";
export var bookBlock = "layout-module--bookBlock--9N+q6";
export var book = "layout-module--book--P0QtX";
export var bookInfo = "layout-module--bookInfo--Om3O0";
export var month = "layout-module--month--8pJi3";
export var titleLink = "layout-module--titleLink--pMWIp";