import * as React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../components/layout-journal'
import {
	card,
	month
} from '../../components/layout.module.css'

const BlogPage = ({ data }) => {
  return (
	<Layout pageTitle="Alive Journal">
		<div className={card}>
				{
  				data.allMdx.nodes.map(node => (
	  				<h2 className={month} key={node.id}>
						<Link to={`/this-seeming-chaos/${node.slug}`}>
		  				{node.frontmatter.title}
						</Link>
	  				</h2>
  				))
				}
 		</div>
	</Layout>
  )
}

export const query = graphql`
  {
	allMdx(sort: {fields: frontmatter___date, order: ASC}) {
	  nodes {
		frontmatter {
		  date(formatString: "MMMM D, YYYY")
		  title
		}
		id
		slug
	  }
	}
  }
`
export default BlogPage